import clientService from '@/services/queries/clientQueries'

export default {
  data() {
    return {
      client: null
    }
  },

  metaInfo() {
    return {
      title: this.$t('agent.clients.editing.pageTitle')
    }
  },

  created() {
    this.getClient()
  },

  methods: {
    getClient() {
      clientService.find(this.$route.params.id).then(data => {
        this.client = data
      })
    }
  }
}